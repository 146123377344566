const routes = [
  {
    path: "/login",
    component: "modules/home/pages/Login",
  },
  {
    path: "/",
    component: "modules/dashboard/pages/Dashboard",
    auth: true,
    roles: ["*"],
  },
  {
    path: "/profile",
    component: "modules/profile/pages/Profile",
    auth: true,
    roles: ["*"],
  },
  {
    path: "/users",
    component: "modules/users/pages/Users",
    auth: true,
    roles: ["Super Admin", "Admin"],
  },
  {
    path: "/time-log",
    component: "modules/time-log/pages/TimeLog",
    auth: true,
    roles: ["*"],
  },
  {
    path: "/time-log/print",
    component: "modules/time-log/pages/PrintTimeLog",
    roles: ["*"],
  },
  {
    path: "/time-log/upload",
    component: "modules/time-log/pages/UploadTimeLog",
    auth: true,
    roles: ["Super Admin", "Admin"],
  },
  {
    path: "/reports",
    component: "modules/time-log/pages/ReportTimeLog",
    auth: true,
    roles: ["Super Admin", "Admin"],
  },
];

export default routes;
