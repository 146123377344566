import { Typography } from "@material-ui/core";
import React from "react";

function Forbidden() {
  return (
    <div>
      <Typography color="error" variant="h5">
        Forbidden
      </Typography>
    </div>
  );
}

export default Forbidden;
