import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { CircularProgress, Typography } from "@material-ui/core";
import * as service from "../../../layouts/service";
import { useDispatch } from "react-redux";
import { setNotifications } from "../../../layouts/notifications/store/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Logout({ open, onClose }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleConfirm = () => {
    setLoading(true);
    service
      .logout()
      .then((res) => {
        if (res.data.revoke) {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(setNotifications(err, "error"));
      });
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} keepMounted>
      <DialogContent>
        <Typography color="error">Are you sure you want to logout?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          disabled={loading}
          variant="contained"
          color="primary"
        >
          {loading ? <CircularProgress size={24} /> : "Yes"}
        </Button>
        <Button
          onClick={onClose}
          disabled={loading}
          variant="contained"
          color="secondary"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
