import { handleErrorResponse } from "../../../utils/helper";
import * as types from "./actionTypes";

export const setNotifications = (message, type = "success") => {
  return {
    type: types.OPEN_NOTIFICATIONS,
    payload: {
      message: handleErrorResponse(message),
      type,
    },
  };
};

export const closeNotifications = () => {
  return {
    type: types.CLOSE_NOTIFICATIONS,
  };
};
