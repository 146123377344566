import * as types from "./actionTypes";

const initialState = {
  user: {},
};

const reducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case types.SET_USER:
      return {
        ...state,
        user: payload,
      };

    default:
      return state;
  }
};

export default reducer;
