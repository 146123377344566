import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import People from "@material-ui/icons/People";
import TimerIcon from "@material-ui/icons/Timer";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { useSelector } from "react-redux";
import Logout from "../modules/auth/components/Logout";
import { isAllowed } from "../utils/helper";
import Forbidden from "./Forbidden";
import AssessmentIcon from "@material-ui/icons/Assessment";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: "auto",
    minHeight: "calc(100vh - 3px)",
  },
}));

export default function Default({ path, children, routeRoles }) {
  const classes = useStyles();
  const history = useHistory();

  const user = useSelector((state) => state.auth.user);

  const isForbidden = isAllowed(user.roles, routeRoles || []);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [showConfirmLogout, setShowConfirmLogout] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const links = [
    {
      url: "/",
      name: "Dashboard",
      icon: <DashboardIcon />,
      roles: ["*"],
    },
    // {
    //   url: "/profile",
    //   name: "Profile",
    //   icon: <Person />,
    //   roles: ["Super Admin"],
    // },
    {
      url: "/users",
      name: "Users",
      icon: <People />,
      roles: ["Admin", "Super Admin"],
    },
    {
      url: "/time-log",
      name: "Time Log",
      icon: <TimerIcon />,
      roles: ["*"],
    },
    {
      url: "/reports",
      name: "Reports",
      icon: <AssessmentIcon />,
      roles: ["*"],
    },
  ];

  const handleNavigate = (url) => {
    history.push(url);
  };

  const handleCloseLogout = () => {
    setShowConfirmLogout(false);
  };

  const handleShowLogout = () => {
    setShowConfirmLogout(true);
    handleClose();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Payroll
          </Typography>
          <div style={{ flex: 1 }}></div>
          <Typography variant="h6">{user.full_name}</Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={handleShowLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {links
            .filter((link) => isAllowed(user.roles, link.roles))
            .map((link, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleNavigate(link.url)}
              >
                {link.icon && <ListItemIcon>{link.icon}</ListItemIcon>}
                <ListItemText primary={link.name} />
              </ListItem>
            ))}
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {!isForbidden ? <Forbidden /> : children}
      </main>

      <Logout open={showConfirmLogout} onClose={handleCloseLogout} />
    </div>
  );
}
