import * as types from "./actionTypes";

const initialState = {
  message: "",
  open: false,
  type: "success",
};

const notificationStore = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case types.CLOSE_NOTIFICATIONS:
      return {
        ...state,
        open: false,
      };

    case types.OPEN_NOTIFICATIONS:
      return {
        ...state,
        open: true,
        ...payload,
      };
    default:
      return state;
  }
};

export default notificationStore;
