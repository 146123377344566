import React, { lazy, Suspense } from "react";
import { Redirect, Route } from "react-router-dom";
import Authenticate from "../layouts/Authenticate";
import Default from "../layouts/Default";
import Loader from "../layouts/Loader";
import { isAuth } from "../utils/helper";

function Private({ component, ...rest }) {
  const Component = lazy(() => import(`../${component}`));

  if (!isAuth()) {
    return <Route {...rest} render={() => <Redirect to="/login" />} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<Loader />}>
          <Authenticate>
            <Default path={props.match.url} routeRoles={rest.roles}>
              <Component {...props} />
            </Default>
          </Authenticate>
        </Suspense>
      )}
    />
  );
}

export default Private;
