import { BrowserRouter, Switch } from "react-router-dom";
import Private from "./route/Private";
import Public from "./route/Public";
import routes from "./route/routes";
import Notifications from "./layouts/notifications/pages/Notifications";

function App() {
  return (
    <>
      <Notifications />
      <BrowserRouter>
        <Switch>
          {routes.map((route, index) => {
            if (route.auth) {
              return <Private exact key={index} {...route} />;
            } else {
              return <Public exact key={index} {...route} />;
            }
          })}
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
