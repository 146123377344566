import { useEffect, useState } from "react";
import * as service from "./service";
import { useDispatch } from "react-redux";
import { setNotifications } from "./notifications/store/actions";
import { makeStyles, Typography } from "@material-ui/core";
import { setUser } from "../modules/auth/store/actions";
import { handleErrorResponse } from "../utils/helper";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
function Authenticate({ children }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setLoading(true);
    service
      .getUser()
      .then((res) => {
        if (res.data) {
          dispatch(setUser(res.data.data));
        }

        setLoading(false);
      })
      .catch((err) => {
        setErrorMessage(handleErrorResponse(err));
        dispatch(setNotifications(err, "error"));
      });
  }, [dispatch]);

  return loading ? (
    <section className={classes.root}>
      <Typography variant="h6">Authenticating...</Typography>
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
    </section>
  ) : (
    children
  );
}

export default Authenticate;
