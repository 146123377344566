import Reevalidate from "ree-validate";

export const Revalidate = (value) => {
  const validator = new Reevalidate(value);

  validator.localize("en", {
    messages: {
      required: "This field is required",
    },
  });

  return validator;
};

// check if authenticated
export const isAuth = () => {
  return !!localStorage.getItem("accessToken");
};

export const handleErrorResponse = (err) => {
  const response =
    (err && err.response && err.response.data && err.response.data) || {};

  if (response.errors && typeof response.errors === "object") {
    let errors = [];
    for (const key in response.errors) {
      if (Object.hasOwnProperty.call(response.errors, key)) {
        if (response.errors[key] && response.errors[key][0]) {
          errors = [...errors, response.errors[key][0]];
        }
      }
    }
    return errors.join("<br />");
  }

  if (response.message) {
    return response.message;
  }

  return err.message || err;
};

export const roles = {
  guard: "Guard",
  admin: "Admin",
  employee: "Employee",
  cashier: "Cashier",
};

export const rolesOptions = ["Admin", "Employee", "Cashier", "Guard"];

export const isAllowed = (userRoles, routeRoles) => {
  let isAllow = routeRoles.includes("*");

  userRoles.forEach((role) => {
    if (routeRoles.includes(role)) {
      isAllow = true;
    }
  });

  return isAllow;
};
